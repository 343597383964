
.profile-image-pic{
  height: 200px;
  width: 200px;
  object-fit: cover;
}
.cardbody-color{
  background-color: #ebf2fa;
}
.add-warehouse-page-wrapper{
  padding: 20px 0;
  .page-title{
    padding: 20px 0 0 0;
    h1{
      font-size: 32px;
      font-weight: bold;
    }
  }
  .form-wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    .form-section{
      h2{
        font-size: 28px;
        font-weight: 600;
      }
    }
    .single-field{
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 10px;
      &.location-btn{
        justify-content: end;
        button{
          border: none;
          background-color: rgb(215, 215, 240);
          height: 50%;
          border-radius: 8px;
        }
      }
      label{
        font-size: 14px;
        font-weight: 400;
      }
      input,select,textarea{
        border: 1px solid rgb(204, 202, 202);
        padding: 10px;
        border-radius: 8px;
        font-size: 14px;
        &:focus{
          outline: none;
        }
      }
    }
    .submit-button{
      max-width: 120px;
      padding: 5px 0;
      background: transparent;
      border-radius: 5px;
      border: none;
      background-color: #a3c2be;
      color: #13544E;

    }
  }
}

.dashboard-header-btns {
  .dropdown {
    .dropdown-toggle {
      all: unset;
      position: relative;
      background-color: #f6f4ffc2 !important;
      border: none;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      align-self: center;
      object-fit: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .header-btn-badge {
        position: absolute;
        width: 22px;
        height: 22px;
        font-size: 14px;
        border-radius: 50%;
        color: var(--white);
        background-color: var(--lime-300);
        right: -10px;
        top: 0;
      }

      &.avatar-rounded-circle {
        background-color: #ffffff !important;

        img {
          height: 100%;
          width: 120px;
        }
      }

      &::after {
        display: none !important;
      }
    }
    .dropdown-menu {
      right: 0;
      min-width: 230px;
      padding: 5px 10px !important;

      .dropdown-item {
        color: var(--dark-200);
        align-items: center;
        &:hover{
          background-color: #a3c2be;
        }
    }
    }
  }
}

.home-page-wrapper{
  height: 100%;
  overflow-y: auto;
  ul{
    li{
      list-style: none;
      a{
        text-decoration: none;
        color: black;
      }
    }
  }
  .home-page-cards{
    margin-top: 40px;
    .single-card{
      a{
        font-weight: 600;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 30px 0;
        align-items: center;
        background-color: #3c8880;
        text-decoration: none;
        color: white;
        height: 250px;
        border-radius: 10px;
        span{
          font-size: 54px;
        }
      }
    }
  }
}

.warehouse-listing {
  .container {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  .page-title{
      text-align: center;
    h1{
      font-size: 48px;
      font-weight: 600;
    }
  }

  
}
.warehouse-listing-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  table{
    width: 100%;
    th, td {
      padding: 12px 15px;
      text-align: left;
      border-bottom: 1px solid #ddd;
      font-size: 14px;
      a{
        text-decoration: none;
        color: black;
      }
    }

    th {
      background-color: #f5f5f5;
      color: #333;
      font-weight: bold;
    }
    tbody{
      tr{
        td{
          span{
              color: #13544E;
              padding: 5px;
              border-radius: 5px;
            &.completed{
              background-color: rgb(194, 221, 154);
              }
            &.danger{
              background-color: rgb(252, 177, 177);
            }
            &.pending{
              background-color: rgb(245, 245, 172);
            }
          }
          
        }
      }
    }
    tbody tr:nth-of-type(even) {
      background-color: #f9f9f9;
    }

    tbody tr:hover {
      background-color: #f1f1f1;
    }

    .action-buttons {
      display: flex;
      gap: 10px;

      button,a {
        background-color: rgb(215, 215, 240);
        border: none;
        color: #13544E;
        padding: 6px 12px;
        font-size: 14px;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #a3c2be;
        }
      }
    }

    .fa-edit, .fa-trash {
      font-size: 16px;
    }
  }
  
}
.sidebar{
  @media screen and (max-width: 991px) {
    display: none;
  }
  ul {
    li {
      padding: 0 !important;
      margin: 0 !important;
      list-style: none;
  
      .sidebar-nav-link {
        display: flex;
        padding: 15px 30px;
        border-radius: 5px;
        gap: 16px;
        align-items: center;
        text-decoration: none !important;
        transition: all 0.3s ease-in-out;
      
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      .icon-image{
        color: #13544E;
        width: 24px;
        height: 24px;
      }
        &.active {
          color: #ffffff;
          background-color: #13544E;
          border-radius: 10px;
          .icon-image{
            color: white;
          }
      
          .title {
            color: #ffffff;
          }
        }
      
        .title {
          color: #5b5b5b;
          font-size: 16px;
        }
      
        .icon-image {
          display: flex;
          align-items: center;
          height: 20px;
          width: 20px;
          object-fit: contain;
        }
      }
      
    }
  }
  
}
.main-page-wrapper{
  display: flex;
}

.main-dashboard-wrapper{
  display: flex;
  position: relative;
  height: calc(100vh - 68px);
  .sidebar-main-wrapper{
    width: 260px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    overflow-y: auto;
  }
  .dashboard-body-wrapper{
    width: 100%;
    margin-left: 260px;
    padding: 40px;
    height: 100%;
    overflow-y: auto;
    &.without-sidebar{
      margin-left: 0;
    }
  }
}

.modal-wrapper{
  // .modal-content{
  //   max-width: 580px;
  //   width: 100%;
  // }
  .modal-body{
    // display: flex;
    // justify-content: center;
    .form-section{
      // display: flex;
      // align-items: center;
      // flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;
      .single-field{
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 10px;
        label{
          font-weight: 400;
          text-transform: capitalize;
        }
        input,select,textarea{
          border: none;
          background-color: rgb(215, 215, 240);
          border-radius: 8px;
          padding: 6px;
          max-width: 350px;
          width: 350px;
          &:focus{
            outline: none;
          }
        }
      }
      h2{
        font-size: 28px;
        font-weight: 600;
      }
    }
    .submit-button{
      max-width: 120px;
      padding: 5px 0;
      background: transparent;
      border-radius: 5px;
      border: none;
      background-color: rgb(215, 215, 240);
      color: rgb(52, 137, 206);
      max-width: 120px;
      width: 100%;
    }
  }
}

.home-btns-wrapper{
  display: flex;
  align-items: center;
  gap: 20px;
}

.user-profile-page-wrapper{
  h1{
    text-align: center;
  }
  .info-fields{
    display: flex;
    flex-direction: column;
    gap: 10px;
.single-field{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  &.role{
    input{
      text-transform: capitalize;
    }
  }
  label{
    font-weight: 400;
    text-transform: capitalize;
}
    input{
      border: none;
      background-color: rgb(215, 215, 240);
      border-radius: 8px;
      padding: 6px;
      width: 100%;
      &:focus{
        outline: none;
      }
    }
  }
  }
}
.backbtn{
  margin-top: 20px;
  background-color: #a3c2be;
  max-width: 120px;
  width: 100%;
  border-radius: 5px;
  a{
    text-decoration: none;
    color: #13544E;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    padding: 5px;
    border-radius: 5px;
  }
}

.no-data-found{
  img{
    width: 200px;
    height: 200px;
  }
  h2{
    font-weight: 600;
    font-size: 32px;
  }
}

.page-wrapper{
  width: 100%;
  .page-title{
    padding: 20px 0;
    text-align: center;
    h1{
      font-size: 48px;
      font-weight: bold;
    }
  }
  .place-order-form{
    .form-wrapper{
      .form-section{
        // box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
        .single-field{
          display: flex;
          flex-direction: column;
          gap: 5px;
          margin-bottom: 20px;
          label{
            font-size: 14px;
            font-weight: 400;
          }
          input,select{
            border: 1px solid #a3c2be;
            background: transparent;
            width: 100%;
            font-size: 14px;
            padding: 5px 10px;
            border-radius: 8px;
            &:focus{
              outline: none;
            }
          }
        }
        
      }
      .submit-button{
        max-width: 120px;
        padding: 5px 0;
        background: transparent;
        border-radius: 5px;
        border: none;
        background-color: rgb(215, 215, 240);
        color: #13544E;
        max-width: 120px;
        width: 100%;
      }
    }
  }
}
.page-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  .filter-dropdown{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    select,input{
      border: none;
      background: transparent;
      border-radius: 5px;
      padding: 5px 10px;
      border: 1px solid #a3c2be;
      font-size: 14px;
      width: 150px;
      &:focus{
        outline: none;
      }
    }
  }
  .search-bar{
    display: flex;
    gap: 10px;
    align-items: center;
    border: 1px solid #a3c2be;
    padding: 5px 10px;
    border-radius: 5px;

    input{
      border: none;
      background: transparent;
      width: 100%;
      font-size: 14px;
      &:focus{
        outline: none;
      }
    }
  }
}

.warehouse-page-wrapper{
    .page-title{
      text-align: center;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .back-btn{
        
        a{
          display: flex;
          gap: 10px;
          align-items: center;
          text-decoration: none;
          color: black;
        }
      }
      
      h1{
        font-size: 40px;
        font-weight: 600;
      }
      .buy-button{
        border: none;
        padding: 10px 0;
        &.active{
          background-color: rgb(194, 221, 154);
          padding: 5px;
          border-radius: 5px;
          color: #13544E;
        }
        &.inactive{
          background-color: rgb(252, 177, 177);
              color: #13544E;
              padding: 5px;
              border-radius: 5px;
        }
      }
    }
    .page-header-section{
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
      padding: 10px;
      border-radius: 10px;
      display: flex;
      gap: 20px;
      margin-top: 40px;
      align-items: center;
      .warehouse-image{
        img{
          width: 300px;
          height: 250px;
        }
      }
      .storage-info{
        .single-info{
          display: flex;
          gap: 10px;
          strong{
            font-weight: 600;
            font-size: 14px;
          }
          span{
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
      .pricing-details{
        .single-info{
          display: flex;
          gap: 10px;
          strong{
            font-weight: 600;
            font-size: 14px;
          }
          span{
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
    .warehouse-basic-info{
      span{
        &.active{
          background-color: rgb(194, 221, 154);
          padding: 5px;
          border-radius: 5px;
          color: #13544E;
        }
        &.inactive{
          background-color: rgb(252, 177, 177);
              color: #13544E;
              padding: 5px;
              border-radius: 5px;
        }
        
      }
    }
    .categories-list{
      margin-top: 20px;
      ul{
        margin: 0;
        padding: 0;
        li{
          list-style: none;
          display: flex;
          gap: 10px;
          align-items: center;
        }
      }
    }
    .services-list{
      margin: 50px 0 0 0;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
      padding: 10px;
      border-radius: 10px;
      h2{
        font-size: 24px;
        font-weight: 600;
      }
      .services-wrapper{
        display: flex;
        gap: 40px;
        margin-top: 30px;
        .single-service{
          display: flex;
          gap: 10px;
          align-items: center;
        }
      }
      
    }
    .description{
      margin-top: 30px;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
      padding: 10px;
      border-radius: 10px;
      p{
        font-size: 18px;
        font-weight: 400;
      }
    }
    .images-gallery{
      margin-top: 40px;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
      padding: 10px;
      border-radius: 10px;
      .single-image{
        margin-top: 30px;
        overflow: hidden;
        img{
          max-width: 100%;
          width: 100%;
          object-fit: cover;

        }
      }
    }
}
.error{
  color: red;
}

$primary-color: #4CAF50; // A vibrant green color for highlights and headings
$secondary-bg-color: #f4f4f9; // A light greyish background for the page
$text-color: #333; // A dark grey color for the main text
$card-bg-color: #f9f9f9; // A soft white color for card backgrounds
$box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Subtle shadow for elements

.order-detail-page-wrapper{
  .page-title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
        text-decoration: none;
        color: black;
        display: flex;
        gap: 10px;
        align-items: center;
    }

    h1 {
        flex: 1; // Ensure equal space for centering
        text-align: center;
        margin-bottom: 20px;
        color: black;
    }

    .approve-btn {
         // Equal space for alignment
        border: none;
        color: #4caf50;
        font-weight: 700;
        background-color: rgb(215, 215, 240);
        padding: 5px 15px;
        border-radius: 8px;
        text-align: right; // Align button to the right
    }

    .empty-space {
         // Placeholder to maintain spacing
    }
}

  
  
  .section {
    margin-bottom: 20px;
  
    h2 {
      margin-bottom: 10px;
      font-size: 1.5em;
      color: $text-color;
      border-bottom: 2px solid black;
      padding-bottom: 5px;
    }
  
    p {
      margin: 5px 0;
      line-height: 1.6;
    }
  }
  
  .flex-row {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .card {
    flex: 1;
    min-width: 300px;
    background: $card-bg-color;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .highlight {
    font-weight: bold;
    color: $primary-color;
  }
  .status {
    margin-top: 10px;
    font-size: 1.1em;
  
    .pending {
      background-color: #f5d165; // Yellow for Pending
      padding: 2px 8px;
      border-radius: 4px;
      color: black;
    }
  
    .shipped {
      background-color: #007BFF; // Blue for Shipped
      padding: 2px 8px;
      border-radius: 4px;
      color: black;

    }
  
    .delivered,.approved {
      background-color: #4fdb70; // Green for Delivered
      padding: 2px 8px;
      border-radius: 4px;
      color: black;

    }
  
    .returned {
      background-color: #DC3545; // Red for Returned
      padding: 2px 8px;
      border-radius: 4px;
      color: black;

    }
  }
  
  @media (max-width: 768px) {
    .flex-row {
      flex-direction: column;
    }
  }
}

.status{
  span{
    .pending{
      background-color: rgb(245, 245, 172);

    }
    
  }
}
.pagination-section{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .controls{
    
    .control{
      display: flex;
    align-items: center;
    gap: 5px;
      button{
        background: transparent;
        border: 1px solid #13544E;
        border-radius: 5px;
        &.count{
          border: none;
          background-color: rgb(215, 215, 240);
          padding: 3px 10px;
        }
      }
    }
  }
}